import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from "../components/Loader"

const SearchModel = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(true);
    const [search, setSearch] = useState('');

    const searchParams = useLocation();
    const navigate = useNavigate();
    const state = searchParams.state || '';
    // console.log(state);


    useEffect(() => {
        setIsMounted(true); // Flag to track whether the component is mounted 

        setTimeout(() => {
            fetchProducts();
        }, 1000)

        return () => {
            setIsMounted(false); // Mark the component as unmounted
        };
    }, [state, search]);


    const fetchProducts = async () => {
        const keywords = search != '' ? search : state.state;
        try {
            const response = await axios.post('https://cashknock.in/admin/api/SearchData', {
                model: keywords,
                categoryId: state.category_id
            });

            if (isMounted) {
                setData(response.data.data);
                setLoading(false);
            }
        } catch (error) {
            if (isMounted) {
                setError(error);
                setLoading(false);
            }
        }
    };

    if (loading) {
        return <Loader />
    }

    console.log(data);


    const handleKeypress = (event) => {
        const { value } = event.target;
        setSearch(value);

    };

    const navigateToSearch = () => {
        fetchProducts();
    };

    const navigateToDetails = (id, url, brand_url, category_url) => {
        // console.log(`/${category_url}/${brand_url}/${url}`);
        const paramsData = { id: id };
        navigate(`/${category_url}/${brand_url}/${url}`, { state: paramsData });
    };



    return (
        <main id="main" className="pt-4">
            <section id="sell-form" className="sell-form">
                <div className="container">
                    <div className="sell-form-bg brand_form_1">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-12 col-md-6">
                                <div className="section-title select_model_heading">
                                    <h2 data-aos="fade-up">Select your model brand</h2>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="search-bar mt-2 search_brand">
                                        <input type="text" placeholder="search model" value={search} onChange={handleKeypress} />
                                        <button onClick={navigateToSearch}>go!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="d-gallery" className="d-gallery">
                <div className="container" >
                    {
                        !loading && data?.length > 0 ?
                            <div className="row device-gallery">
                                {
                                    data.map(option => (
                                        <div key={option.id} className="col-lg-2 col-md-3 col-6">
                                            <div className="product-img">
                                                <a href="javascript:void(0)" onClick={() => navigateToDetails(option.id, option.url, option.category_url, option.brand_url)}>
                                                    <img src={option.image} className="img-fluid" alt="" />
                                                    <h4>{option.model}</h4>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            : <div className="row device-gallery d-flex justify-content-center">
                                <div className='col-md-6'>
                                    <img src='../assets/img/no-data.webp' className='img-fluid' alt="No data" />
                                </div>
                            </div>
                    }
                </div>
            </section>
        </main>
    );
};

export default SearchModel;
