import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import SellDevice from "./pages/SellDevice";
import BrandDeatails from "./pages/BrandDetails"
import AllCategories from "./pages/AllCategories";
import SellBrandwise from "./pages/SellBrandWise";
import BrandModel from "./pages/BrandModel";
import ProductDetails from "./pages/ProductDetails";
import ExactValue from "./pages/ExactValue";
import SellQuoteDetails from "./pages/SellQuoteDetails";
import Checkout from "./pages/checkout";
import SearchModel from "./pages/SearchModel";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserProfile from "./pages/UserProfile";
import BlogList from "./pages/BlogList";
import BlogDetails from "./pages/BlogDetails";
import ComingSoon from "./pages/ComingSoon";

// import { useEffect } from "react";
// import Aos from "aos";
function App() {

  // useEffect(() => {
  //   Aos.init({
  //     duration: 1000,
  //   });
  //   document.addEventListener("DOMContentLoaded", function () {
  //     setTimeout(function () { Aos.refresh(); }, 500);
  //   });
  // }, [])

  return (
    <Router>
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/sell-device" element={<SellDevice />} />
          {/* <Route path="/brand-details" element={<BrandDeatails />} /> */}
          <Route path="/all-categories" element={<AllCategories />} />
          <Route path="/sell-brandwise" element={<SellBrandwise />} />
          {/* <Route path="/brand-model" element={<BrandModel/>}/> */}
          <Route path="/:categorySlug/:brandSlug" element={<BrandModel />} />
          <Route path="/search" element={<SearchModel />} />
          {/* <Route path="/product-details" element={<ProductDetails/>}/> */}
          <Route path="/:categorySlug/:brandSlug/:productname" element={<ProductDetails />} />
          {/* <Route path="/brand-model" element={<BrandModel/>}/> */}
          {/* <Route path="/product-details" element={<ProductDetails/>}/> */}
          <Route path="/exact-value" element={<ExactValue />} />
          <Route path="/sell-quote-details" element={<SellQuoteDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:blogDetails" element={<BlogDetails />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </div>
      <ToastContainer />
      <Footer />
    </Router>
  );
}
export default App;
