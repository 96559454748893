import { Button, Card, Form, Input, Modal, Radio, Space, TimePicker } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';



// Function to calculate the next four days with day names, including today if before 3 PM
const getNextDaysWithDayNames = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();

    // Get Indian Standard Time (IST) by adjusting the UTC offset (IST is UTC +5:30)
    const ISTOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const ISTTime = new Date(today.getTime() + ISTOffset);

    const hours = today.getHours();
    const nextDays = [];
    

    if (hours < 17) {
        nextDays.push({
            date: ISTTime.toISOString().split('T')[0], // Adjusted date in IST
            day: days[ISTTime.getDay()],
        });
        for (let i = 1; i <= 6; i++) {
            const nextDate = new Date(ISTTime);
            nextDate.setDate(ISTTime.getDate() + i);
            nextDays.push({
                date: nextDate.toISOString().split('T')[0], // Adjusted date in IST
                day: days[nextDate.getDay()],
            });
        }
    } else {
        for (let i = 1; i <= 7; i++) {
            const nextDate = new Date(ISTTime);
            nextDate.setDate(ISTTime.getDate() + i);
            nextDays.push({
                date: nextDate.toISOString().split('T')[0], // Adjusted date in IST
                day: days[nextDate.getDay()],
            });
        }
    }

    return nextDays;
};


const getDayName = (dayNumber) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[dayNumber];
};

const Checkout = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nextDays] = useState(getNextDaysWithDayNames());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(nextDays.length > 0 ? nextDays[0].date : '');
    const [addressOption, setAddressOption] = useState(null);
    const [addresses, showAllAddress] = useState(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [confirmOrder, setConfirmOrder] = useState(true);
    const [checkAddress, setCheckAddress] = useState(true);
    const [pickupSlot, setPickupSlot] = useState(null);





    const navigate = useNavigate();

    let storedOrderDetails = JSON.parse(localStorage.getItem('order-details'));

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        values.user_id = parseInt(localStorage.getItem('user_id'));
        values.address_option = addressOption;
        storeAddress(values);
        setIsModalVisible(false);
    };

    const storeAddress = async (values) => {
        try {
            const checkPinCode = await axios.post("https://cashknock.in/admin/api/checkPincode", {
                pincode: values.pincode
            })
            if (checkPinCode.data.status === false) {
                toast.error("Service is not available for this pincode !")
                return;
            }
            const response = await axios.post('https://cashknock.in/admin/api/address', values);
            fetchAddresses();
            showAllAddress(response.data.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };




    const placeOrder = async () => {
        const values = {};
        values.order_id = storedOrderDetails.order_id;
        values.user_id = storedOrderDetails.user_id;
        values.address_id = selectedAddressIndex;
        values.model_id = storedOrderDetails.model_id;
        values.variant_id = storedOrderDetails.variant_id;
        values.pickup_day = getDayName(new Date(selectedDate).getDay());
        values.shedule_date = selectedDate;
        values.pickup_time = pickupSlot;
        values.payment_mode = 'COD';
        values.order_amount = storedOrderDetails.totalAmount;
        console.log(values);
        setConfirmOrder(false);
        try {
            const response = await axios.post('https://cashknock.in/admin/api/orderdetail', values);
            if (response.data.status == 1) {
                toast.success(`We have received your request, we will contact you soon`);
                setLoading(false);
                setConfirmOrder(true);
                navigate('/', { replace: true });
            } else {
                toast.error("Failed to send request");
                if (values.address_id == null) {
                    setCheckAddress(false);
                }

                setConfirmOrder(true);
            }
        } catch (error) {
            setError(error);
            setLoading(false);
            setCheckAddress(false);
            setConfirmOrder(true);
            toast.error("Failed to place order, please try again...");
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const onAddressOptionChange = e => {
        setAddressOption(e.target.value);
    };

    let userid = parseInt(localStorage.getItem('user_id'));
    const fetchAddresses = async () => {
        try {
            const response = await axios.post('https://cashknock.in/admin/api/getAddressByUserId', { user_id: userid });
            const addresses = response.data.data;

            // Check if addresses is an array and slice the latest 3 addresses
            const latestAddresses = Array.isArray(addresses) ? addresses.slice(-3) : [];

            showAllAddress(latestAddresses);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAddresses();
    }, []);

    const handleSelectAddress = (index) => {
        setSelectedAddressIndex(index);
        setCheckAddress(true);
    };


    let formattedTotalAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR'
    }).format(storedOrderDetails.totalAmount);

    const instatantPickupSlots = [
        {
            slot: "09:00AM - 11:00AM",
            value: "09:00AM to 11:00AM",
            slotTime: 9
        },
        {
            slot: "11:00AM - 01:00PM",
            value: "11:00AM to 01:00PM",
            slotTime: 11
        },
        {
            slot: "01:00PM - 03:00PM",
            value: "01:00PM to 03:00PM",
            slotTime: 13
        },
        {
            slot: "03:00PM - 05:00PM",
            value: "03:00PM to 05:00PM",
            slotTime: 15
        },
        {
            slot: "05:00PM - 07:00PM",
            value: "05:00PM to 07:00PM",
            slotTime: 17
        }
    ]

    const handlePickTime = (e) => {
        setPickupSlot(e.target.value);
    }


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Checkout</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <div className="breadcrumb-bg">
                            <h2>Checkout</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Checkout</li>
                                <li>Checkout Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="sell-checkout" className="sell-checkout">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-lg-8 col-md-6 col-12">
                                <div className="d-detail checkout">
                                    <div className="mb-4 bord-b">
                                        <h4>1.&nbsp; Pickup Address</h4>
                                    </div>
                                    <Space direction="horizontal" size={16}>
                                        {!loading && addresses && addresses.map((address, index) => (
                                            <Card
                                                title={`Address (${address.address_option})`}
                                                style={{
                                                    width: 250,
                                                    cursor: 'pointer',
                                                    border: selectedAddressIndex === address.id ? '2px solid #14561d' : '1px solid #f0f0f0',
                                                }}
                                                key={address.id}
                                                onClick={() => handleSelectAddress(address.id)}
                                            >
                                                <p>{address.flat}, {address.locality}</p>
                                                <p>{address.landmark}</p>
                                                <p>{address.city}, {address.state} - {address.pincode}</p>
                                                <p>Alternate No: {address.alternate_no}</p>
                                            </Card>
                                        ))}
                                    </Space>
                                    {
                                        checkAddress ? <Button type="sucess" className="continue-btn" onClick={showModal}>
                                            Add Address
                                        </Button> : <>
                                            <h6 style={{ color: "red", marginTop: "10px" }}>Please provide/select the address*</h6>
                                            <Button type="sucess" className="continue-btn" onClick={showModal}>
                                                Add Address
                                            </Button>
                                        </>
                                    }

                                    <Modal title="Address" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                                        <Form layout="vertical" onFinish={onFinish}>
                                            <Form.Item
                                                name="pincode"
                                                label="ZIP Code"
                                                rules={[{ required: true, message: 'Please input your ZIP code!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="flat"
                                                label="Flat"
                                                rules={[{ required: true, message: 'Please input your flat!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="locality"
                                                label="Street"
                                                rules={[{ required: true, message: 'Please input your street!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="landmark"
                                                label="Landmark"
                                                rules={[{ required: true, message: 'Please input your landmark!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="city"
                                                label="City"
                                                rules={[{ required: true, message: 'Please input your city!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="state"
                                                label="State"
                                                rules={[{ required: true, message: 'Please input your state!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="alternate_no"
                                                label="Alternate No"
                                                rules={[{ required: true, message: 'Please input your state!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <div className="save-add mt-4">
                                                <h6>Save As</h6>
                                                <Radio.Group onChange={onAddressOptionChange} value={addressOption}>
                                                    <Radio value="Home">Home</Radio>
                                                    <Radio value="Office" style={{ marginLeft: 20 }}>Office</Radio>
                                                    <Radio value="Other" style={{ marginLeft: 20 }}>Other</Radio>
                                                </Radio.Group>
                                            </div>
                                            <Form.Item>
                                                <Button type="success" className="continue-btn" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Modal>

                                    <div id="pickup" className="mt-5">
                                        <div className="mb-4 bord-b">
                                            <h4>2.&nbsp; Pickup Slot</h4>
                                        </div>
                                        <div className="row pickup-date">
                                            <div >
                                                <p><strong>Please select your preferable pickup date</strong></p>
                                            </div>
                                            {nextDays.map((day, index) => (
                                                <div className="col-6 col-lg-3" key={day.date}>
                                                    <input
                                                        type="radio"
                                                        name="pickupDate"
                                                        id={`pickupDate_${index}`}
                                                        value={day.date}
                                                        checked={selectedDate === day.date}
                                                        onChange={handleDateChange}
                                                    />
                                                    <label htmlFor={`pickupDate_${index}`} className='d-flex flex-column align-items-center '>
                                                        <h6>{day.date.split('-')[2]}</h6>
                                                        <p>{day.day}</p>
                                                    </label>
                                                </div>
                                            ))}

                                        </div>

                                        <div className="row mt-4">
                                            <div>
                                                <p><strong>Please select your preferable pickup time</strong></p>
                                            </div>
                                            <div className='pickup-slot'>
                                                <div className='normal-slot mb-4'>
                                                    <h4>Normal Pickup</h4>
                                                    <div className='my-2'>
                                                        <input type="radio" name="hello" id="pickupSlot1" value="08:00AM to 01:00PM" className='border' onChange={handlePickTime} defaultChecked />
                                                        <label className="border px-2 rounded" for="pickupSlot1">08:00AM - 01:00PM</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" name="hello" id="pickupSlot2" value="01:00PM to 09:00PM" className='border'
                                                            onChange={handlePickTime} />
                                                        <label className="border px-2 rounded" for="pickupSlot2">01:00PM - 09:00PM</label>
                                                    </div>
                                                </div>
                                                {
                                                    selectedDate.split("-")[2] == new Date(Date.now()).getDate() ?
                                                        <div className='instant-slot'>
                                                            <h4 className='mb-0'>Instant Pickup (Only for today)</h4>
                                                            <p className='text-success'><strong>Instant pickup within 2 hours</strong></p>
                                                            {
                                                                instatantPickupSlots?.map((ele, ind) =>
                                                                    new Date(Date.now()).getHours() >= ele.slotTime ? <div className='my-2'>
                                                                        <input type="radio" name="hello" id={`pickupSlot_${ind}`} value="" className='border' />
                                                                        <label className="border px-2 rounded pe-none opacity-75" for={`pickupSlot_${ind}`} style={{ width: "165px" }}>{ele.slot}</label>
                                                                    </div> :
                                                                        <div className='my-2'>
                                                                            <input type="radio" name="hello" id={`pickupSlot_${ind}`} value={ele.value} className='border'
                                                                                onChange={handlePickTime} />
                                                                            <label className="border px-2 rounded" for={`pickupSlot_${ind}`} style={{ width: "165px" }}>{ele.slot}</label>
                                                                        </div>)
                                                            }
                                                        </div> :
                                                        null
                                                }


                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 p-summary">
                                <div className="d-detail mb-3">
                                    <div className="model-detail">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-6 col-12"> <img src={localStorage.getItem('product_image')} alt="cashknock" /> </div>
                                            <div className="col-lg-7 col-md-6 col-12">
                                                <div className="model-title">
                                                    <h5 data-aos="fade-up" className="aos-init aos-animate">{storedOrderDetails.productName} ({storedOrderDetails.variant})</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="evaluate-sidebar">
                                    <div className="d-flex align-items-center bord-b">
                                        <h4>Price Summary</h4>
                                    </div>
                                    <div className="row mt-3 bord-b">
                                        <div className="col-md-8">
                                            <h6>Base Price</h6>
                                        </div>
                                        <div className="col-md-4">
                                            <h6>{formattedTotalAmount}</h6>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-3 bord-b">
                                        <div className="col-md-8">
                                            <h6>Pick Up Charges</h6>
                                        </div>
                                        <div className="col-md-4">
                                            <h6><b>Free</b> <strike>₹ 100</strike></h6>
                                        </div>
                                    </div>
                                    <div className="row mt-3 bord-b">
                                        <div className="col-md-8">
                                            <h6>Processing Fee</h6>
                                        </div>
                                        <div className="col-md-4">
                                            <h6><b>-₹ 29</b> <strike>₹ 100</strike></h6>
                                        </div>
                                    </div> */}
                                    <div className="row mt-3 ">
                                        <div className="col-md-8">
                                            <h6><strong>Total Amount</strong></h6>
                                        </div>
                                        <div className="col-md-4">
                                            <h6><strong>{formattedTotalAmount}</strong></h6>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center mt-3">
                                        {
                                            confirmOrder ? <a className="sum-btn" href="javascript:void(0)" onClick={placeOrder}>Confirm Order</a> : <a className="sum-btn pe-none" href="javascript:void(0)" onClick={placeOrder}>Placing order...</a>
                                        }

                                    </div>
                                </div>
                                <div className="evaluate-sidebar mt-4">
                                    <div className="payment">
                                        <h4 className=" bord-b mb-4">Payment Options</h4>
                                        <ul>
                                            <li><a href="#"><img src="assets/img/payment/upi.webp" alt="cashknock UPI" /></a></li>
                                            <li><a href="#"><i className="bx bxs-wallet"></i> Instant Cash</a></li>
                                            <li><a href="#"><i className="bx bxs-bank"></i> Bank Transfer</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Checkout;
