import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader';

const ComingSoon = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, []);

    if (loading) {
        return <Loader />
    }


    return (
        <div className='d-flex justify-content-center align-items-center my-4'>
            <img src="/assets/img/coming-soon-app-store.png" alt="coming-soon" width="500px"/>
        </div>
    )
}

export default ComingSoon