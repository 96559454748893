import React from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
const Contact = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Cashknock - Contact
                    </title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>Connect With Us</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Contact Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="contact" class="contact">
                    <div class="container">

                        <div class="section-title">
                            <h2 data-aos="fade-up">Contact</h2>
                            <p data-aos="fade-up">Please fill the form below to get instant response to your query</p>
                        </div>

                        <div class="row justify-content-center">

                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
                                <div class="info-box">
                                    <i class="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p> D-54, Second Floor, Sector - 2, Noida</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
                                <div class="info-box">
                                    <i class="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>support@cashknock.in</p><br />
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
                                <div class="info-box">
                                    <i class="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 8800390953</p><br />
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
                            <div class="col-xl-9 col-lg-12 mt-4">
                                <form action="forms/contact.php" method="post" role="form" class="php-email-form">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required />
                                        </div>
                                        <div class="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div class="form-group mt-3">
                                        <textarea class="form-control" name="message" rows="5" placeholder="Message" required ></textarea>
                                    </div>
                                    <div class="my-3">
                                        <div class="loading">Loading</div>
                                        <div class="error-message"></div>
                                        <div class="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div class="text-center"><button type="submit">Send Message</button></div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>



                <section id="services" class="services section-bg-2">
                    <div class="container">

                        <div class="section-title text-white" data-aos="fade-up">
                            <h2 class="text-white">Just Few Steps To Sell Your Phone</h2>
                            <p>Book a free pickup from your home or work at a time slot as per your convenience</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6" data-aos="fade-up">
                                <div class="icon-box">

                                    <div class="step-img"><img src="assets/img/steps/1.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>
                                    <h4 class="title"><a href="">Get Quotation</a></h4>
                                    <p class="description text-white">Select your device & tell us about its current condition, and our advanced AI tech will tailor make the perfect price for you.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up">
                                <div class="icon-box">
                                    <div class="step-img"><img src="assets/img/steps/2.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>

                                    <h4 class="title"><a href="">Select Location and Schedule Pickup</a></h4>
                                    <p class="description text-white">Free pickup service from a location of your choice and convenience. We'll get in touch instantly after the price confirmation.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6" data-aos="fade-up">
                                <div class="icon-box">
                                    <div class="step-img"><img src="assets/img/steps/3.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>

                                    <h4 class="title"><a href="">Get Paid Instantly</a></h4>
                                    <p class="description text-white">Receive payment before your phone leaves your hand. Mode of payment? Take your pick.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Contact;
