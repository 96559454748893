import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader';


const BlogList = () => {
    const [blogList, setblogList] = useState();
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        getBlogList();
    }, [])

    const getBlogList = async () => {
        try {
            setLoading(true);
            const res = await axios.get("https://cashknock.in/admin/api/getBlog");
            setblogList(res.data.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGetDetail = (url) => {
        const params = { url: url };
        navigate(`${url}`, { state: params })
    }

    if (Loading) {
        return <Loader />
    }



    return (
        <main>
            <div id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <div class="breadcrumb-bg">
                        <h2>Blogs</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>Blogs</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div class="container pb-5">
                <div class="row mt-n5">
                    {
                        blogList?.map((ele) =>
                            parseInt(ele.status, 10) === 1 && (
                                <div class="col-md-6 col-lg-4 mt-5 wow fadeInUp" data-wow-delay=".6s"
                                    style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInUp" }}
                                >
                                    <div class="blog-grid">
                                        <div class="blog-grid-img position-relative"><img alt="img" src={ele.image} height="220px" /></div>
                                        <div class="blog-grid-text p-4">
                                            <h3 class="h5 mb-3"><a href="#" onClick={() => handleGetDetail(ele.url, ele.id)}>{ele.heading}</a></h3>
                                            <p class="display-30">{ele.short_description.substring(0, 100)}...</p>
                                            <div class="meta meta-style2">
                                                <ul>
                                                    <li>
                                                        <a href="#!"><i class="bi bi-calendar-check me-2"></i>{ele.blog_date}</a></li>
                                                    <li><a href="#!"><i class="fas fa-user"></i> Created by - {ele.blog_by}</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )

                        )
                    }
                </div>
            </div >
        </main>
    )
}

export default BlogList